import SectionHeading from "../UI/SectionHeading";

import classes from "./Contact.module.css";

function Contact() {
  return (
    <section id="contact">
      <div className={classes["contact-container"]}>
        <SectionHeading>Contact Us</SectionHeading>
        <div className={classes["contact-details"]}>
          <p>
            <span>Adress:</span>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/maps/place/32%C2%B052'41.1%22N+13%C2%B011'53.3%22E/@32.8780718,13.1959547,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x8fdbfd9d87c3d67a!8m2!3d32.8780718!4d13.1981434"
            >
              Almoedat St, Ben Achour Area Tripoli
            </a>
          </p>
          <p>
            <span>Phone Numbers:</span>{" "}
            <a href="tel:+218922136862">+218-922136862</a>
            {" & "}
            <a href="tel:+218912136862">+218-912136862</a>
          </p>
          <p>
            <span>Email:</span>{" "}
            <a href="mailto:info@aljessr.com">info@aljessr.com</a>
          </p>
        </div>
        <div className={classes["footer"]}>
          <p>© Copyright {new Date().getFullYear()}, Aljessr International.</p>
        </div>
      </div>
    </section>
  );
}

export default Contact;
