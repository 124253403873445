import { useInView } from "react-intersection-observer";

import classes from "./Room.module.css";

function Room(props) {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const { room, index, imageFolder, roomSector, imageFirst } = props;

  const firstRoom = (
    <>
      <img
        src={`${imageFolder}${index}.png`}
        alt={`Aljessr ${roomSector} ${room[0]} Room`}
      />
      <h4>
        {" "}
        <span className={classes["room-arrow"]}>←</span>{" "}
        <span className={classes["room-name"]}>
          {room[0]} Room{" "}
          <span className={classes["room-details"]}>{room[1]}</span>
        </span>
      </h4>
    </>
  );

  const secondRoom = (
    <>
      <h4>
        {" "}
        <span className={classes["room-name"]}>
          {room[0]} Room{" "}
          <span className={classes["room-details"]}>{room[1]}</span>
        </span>
        <span className={classes["room-arrow"]}>→</span>{" "}
      </h4>
      <img
        src={`${imageFolder}${index}.png`}
        alt={`Aljessr ${roomSector} ${room[0]} Room`}
      />
    </>
  );

  console.log(inView);

  return (
    <div ref={ref}>
      {!inView && (
        <div
          className={`${classes["room-container"]} ${classes["not-visible"]}`}
        >
          {imageFirst ? firstRoom : secondRoom}
        </div>
      )}
      {inView && (
        <div
          className={`${classes["room-container"]} ${
            imageFirst ? classes["left-animation"] : classes["right-animation"]
          }`}
        >
          {imageFirst ? firstRoom : secondRoom}
        </div>
      )}
    </div>
  );
}

export default Room;
