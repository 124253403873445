import { nanoid } from "nanoid";

import Room from "../UI/Room";

import SectionHeading from "../UI/SectionHeading";

import classes from "./PathologyLab.module.css";

function PathologyLab() {
  const roomsDetails = [
    [
      "Accessioning",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ],
    [
      "Tissue Grossing",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ],
    [
      "Processing",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ],
    [
      "Tissue Embedding",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ],
    [
      "Microtomy",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ],
    [
      "Staining and Coverslipping",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ],
    [
      "Cytology",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ],
    [
      "Storage",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ],
    [
      "Reading and Interpretation",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ],
  ];

  return (
    <section id="pathologyLab">
      <SectionHeading>Pathology Lab</SectionHeading>
      <div className={classes["pathologylab-container"]}>
        {roomsDetails.map((room, i) => {
          return (
            <Room
              key={nanoid(6)}
              room={room}
              index={i + 1}
              imageFolder={`${process.env.PUBLIC_URL}/assets/pathologyLabRooms/`}
              roomSector="Pathology Lab"
              imageFirst={(i + 1) % 2 > 0}
            />
          );
        })}
      </div>
    </section>
  );
}

export default PathologyLab;
