import classes from "./Button.module.css";

function Button(props) {
  const sizeClass = props.small ? classes["btn-sm"] : "";

  return (
    <button className={`${classes.btn} ${sizeClass}`}>
      <a href={props.link}>{props.children}</a>
    </button>
  );
}

export default Button;
