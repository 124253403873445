import { Carousel } from "react-responsive-carousel";
import { nanoid } from "nanoid";

import SectionHeading from "../UI/SectionHeading";

import classes from "./Services.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Services() {
  const sliderImgs = [
    ["Pathology Lab", "PATHOLOGY-IMG.jpg"],
    ["Blood Bank", "BLOOD-BANK-IMG.jpg"],
    ["Chemicals", "CHEMICALS-IMG.jpg"],
  ];

  function sliderArrowPrev(onClickHandler, hasPrev, label) {
    return (
      <button
        type="button"
        onClick={onClickHandler}
        disabled={hasPrev ? false : true}
        className={`${classes["slider-arrow"]} ${classes["slider-arrow__left"]}`}
      >
        <i className="bi bi-arrow-left"></i>
      </button>
    );
  }

  function sliderArrowNext(onClickHandler, hasNext, label) {
    return (
      <button
        type="button"
        onClick={onClickHandler}
        disabled={hasNext ? false : true}
        className={`${classes["slider-arrow"]} ${classes["slider-arrow__right"]}`}
      >
        <i className="bi bi-arrow-right"></i>
      </button>
    );
  }

  return (
    <section id="services">
      <SectionHeading>Our Services</SectionHeading>
      <div className={classes["services-container"]}>
        <div className={classes["services-slider"]}>
          <Carousel
            showStatus={false}
            showThumbs={false}
            emulateTouch={true}
            useKeyboardArrows={true}
            autoPlay={true}
            infiniteLoop={true}
            renderArrowPrev={sliderArrowPrev}
            renderArrowNext={sliderArrowNext}
          >
            {sliderImgs.map((img) => (
              <div key={nanoid(6)} className={classes["slider-item"]}>
                <div className={classes["slider-text"]}>
                  <h3>{img[0]}</h3>
                </div>
                <div key={nanoid(6)} className={classes["slider-img"]}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/slider/${img[1]}`}
                    alt="Aljessr services"
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}

export default Services;
