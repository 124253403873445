import { nanoid } from "nanoid";

import Room from "../UI/Room";

import SectionHeading from "../UI/SectionHeading";

import classes from "./BloodBank.module.css";

function BloodBank() {
  const roomsDetails = [
    [
      "Examination",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ],
    [
      "Donation Grossing",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ],
    [
      "Separation",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ],
    [
      "Laboratory",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ],
    [
      "Storage",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ],
    [
      "Distribution",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    ],
  ];

  return (
    <section id="bloodBank">
      <SectionHeading>Blood Bank</SectionHeading>
      <div className={classes["bloodbank-container"]}>
        {roomsDetails.map((room, i) => {
          return (
            <Room
              key={nanoid(6)}
              room={room}
              index={i + 1}
              imageFolder={`${process.env.PUBLIC_URL}/assets/bloodBankRooms/`}
              roomSector="Blood Bank"
              imageFirst={(i + 1) % 2 > 0}
            />
          );
        })}
      </div>
    </section>
  );
}

export default BloodBank;
