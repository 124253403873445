import Button from "../UI/Button";

import classes from "./About.module.css";

function About() {
  const aboutAljessr =
    "Located in Tripoli- Libya, Aljessr Medical Company is a pioneer medical provider for the pathology line, blood banks. Aljessr Medical Company's main mission is to offer the full solution to its customers in term of medical devices, medical consumables, reagents and chemicals.";

  return (
    <section id="about">
      <div className={classes["about-container"]}>
        <h1>
          <span className={classes["brand-highlight"]}>ALJESSR</span> Company is
          a Medical Equipment Supplier
        </h1>
        <p className={classes["about-text"]}>{aboutAljessr}</p>
        <a href="#contact">
          <Button>Get In Touch</Button>
        </a>
      </div>
    </section>
  );
}

export default About;
