import Navbar from "./components/UI/Navbar";
import Main from "./components/UI/Main";
import About from "./components/sections/About";
import Delimiter from "./components/UI/Delimiter";
import Services from "./components/sections/Services";
import PathologyLab from "./components/sections/PathologyLab";
import BloodBank from "./components/sections/BloodBank";
import Contact from "./components/sections/Contact";

function App() {
  return (
    <>
      <Navbar />
      <About />
      <Delimiter />
      <Main>
        <Services />
        <Delimiter />
        <PathologyLab />
        <Delimiter />
        <BloodBank />
      </Main>
      <Contact />
    </>
  );
}

export default App;
