import { useEffect, useState } from "react";

import classes from "./Navbar.module.css";

function Navbar() {
  const [isShrinked, setIsShrinked] = useState(false);
  const [navbarShrinkClass, setNavbarShrinkClass] = useState(null);
  const [collapseNavbar, setCollapseNavbar] = useState(false);

  useEffect(() => {
    const scrollHandler = function () {
      const checkScrollHeight =
        document.body.scrollTop > 80 || document.documentElement.scrollTop > 80;

      if (checkScrollHeight && !isShrinked) {
        setNavbarShrinkClass(classes["navbar-shrinked"]);
        setIsShrinked(true);
        return;
      }

      if (!checkScrollHeight && isShrinked) {
        setNavbarShrinkClass(null);
        setIsShrinked(false);
      }
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [isShrinked]);

  useEffect(() => {
    if (window.innerWidth < 832) {
      setCollapseNavbar(true);
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth < 832) {
        setCollapseNavbar(true);
      } else {
        setCollapseNavbar(false);
      }
    });
  }, []);

  return (
    <nav className={`${classes.navbar} ${navbarShrinkClass || ""}`}>
      <div className={classes["navbar-brand"]}>
        <a href="/">
          {!isShrinked && !collapseNavbar ? (
            <img
              src={`${process.env.PUBLIC_URL}/assets/header/ALJESSR-LOGO-TEXT.png`}
              alt="ALJESSR Brand Logo"
            />
          ) : (
            <h2>ALJESSR</h2>
          )}
        </a>
      </div>
      <div className={classes["navbar-items"]}>
        <ul className={classes["navbar-items__list"]}>
          <li>
            <a href="#about">
              {!collapseNavbar ? "About Us" : <i class="bi bi-info-square"></i>}
            </a>
          </li>
          <li>
            <a href="#services">
              {!collapseNavbar ? "Our Services" : <i class="bi bi-sliders"></i>}
            </a>
          </li>
          <li>
            <a href="#pathologyLab">
              {!collapseNavbar ? (
                "Pathology Lab"
              ) : (
                <i class="bi bi-hospital"></i>
              )}
            </a>
          </li>
          <li>
            <a href="#bloodBank">
              {!collapseNavbar ? "Blood Bank" : <i class="bi bi-bank"></i>}
            </a>
          </li>
          <li>
            <a href="#contact">
              {!collapseNavbar ? (
                "Contact Us"
              ) : (
                <i class="bi bi-person-lines-fill"></i>
              )}
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
